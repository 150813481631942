import "./App.css";
import { Home } from "./pages/Home";
import { Modpacks } from "./pages/Modpacks/Modpacks";
import { Mods } from "./pages/Mods/Mods";
import { About } from "./pages/About";
import NavBar from "./components/NavBar.jsx";
import Footer from "./components/Footer.jsx";
import React from "react";
//import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>		
		<Route path="/modpack/*" element={<Modpacks />} />
		<Route path="/mod/*" element={<Mods />} />
		<Route path="/about/*" element={<About />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
