// import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <p>&copy; 2023 Team BBL</p>
      </footer>
    </>
  );
}
