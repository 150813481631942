import styles from "./About.module.css";
import React from "react";

export const About = () => {
	
  return (
    <>
      <div className={styles.app}>
        <main>
          <section>
            <h2>About</h2>
          </section>
          <section>
		  </section>
        </main>
      </div>
    </>
  );
};
