import styles from "./Mods.module.css";
import React from "react";
import {Route, Link, Routes, useLocation} from 'react-router-dom';

export const Mods = () => {
  const ModImages = [
    "/assets/caveopolis_mod_logo.png",
    "/assets/opolis_miners_logo.png",
    "/assets/opolis_utilities_logo.png",
    "/assets/techopolis_logo.png",
  ];
  const Moddescriptions = [
    "Caveopolis is a mod that adds many colored stone variants for building, and spray paints for colouring different things to the colours you desire!",
    "This mod creates big multiblock structures to mine ores passively and automatically for you.",
    " Provides many cool, unique, and helpful items and blocks to assist your journey! See mod description for full list of new things added.",
    "Techopolis Mod that adds supporting blocks for the Techopolis Modpack.",
  ];
  
  // TODO - make logic cleaner for multi-word mods
  const modName = (useLocation().pathname.charAt(5).toUpperCase() + useLocation().pathname.substr(6)).split("_").join(" ")
  const imageName = useLocation().pathname.substr(5)
  var imageToDisplay = "";
  var textToDisplay = "";
  var i = 0;
  ModImages.forEach((image) => {
	if (image.includes(imageName)) {
		imageToDisplay = image;
		textToDisplay = Moddescriptions[i];
	}
	i++
  })
  return (
    <>
      <div className={styles.app}>
        <main>
          <section>
            <h2>About {modName}</h2>
			<img src={imageToDisplay} />
          </section>
          <section>
		  {textToDisplay}
		  </section>
        </main>
      </div>
    </>
  );
};
