import styles from "./Home.module.css";
import React from "react";
import Carousel from "../components/Carousel.jsx";
import ModCarousel from "../components/ModCarousel.jsx";

export const Home = () => {
  const ModpackImages = [
    "/assets/omniopolis.png",
    "/assets/frozenopolis.png",
    "/assets/techopolis2.png",
    "/assets/seaopolis.png",
    "/assets/techopolis.png",
    "/assets/cryptopolis.png",
    "/assets/caveopolis.png",
  ];
  const Modpackdescriptions = [
    "Kitchen sink style modpack featuring a lot of mods, has famous and popular mods as well as community made mods. Has mod votes to add new mods!",
    "The latest collab with Crypt1c and Benbenlaw! Can you survive the harsh ice world? Featuring custom mods and a heat system.",
    "Techopolis 2 is a technology based modpack. Featuring custom mod 'Opolis Miners' to produce ores for you. Play your way with 4 world types normal, hexlands, skyblock and super flat!",
    "An ocean-based survival and questing modpack. Featuring a completly customized world filled with various different mobs and structures.",
    "Techopolis is a technology based modpack based in a custom overworld with a focus on mining and exploration. Featuring custom machines, recipes and famous mods like Mekanism, Thermal and Immersive Engineering. Now with skyblock mode!",
    "The first cooperation pack between Benbenlaw and Crypt1C! Involving mods like Create, Immersive Engineering and even a couple of custom utils mods that help you through your journey",
    "Caveopolis is a questing based modpack for Minecraft 1.18.2. Journey your way down the world, creating new blocks and machines along the way. Features mods such as Thermal, Mekanism and Custom Machinery. How Low Can You Go?",
  ];
  const ModImages = [
    "/assets/caveopolis_mod_logo.png",
    "/assets/opolis_miners_logo.png",
    "/assets/opolis_utilities_logo.png",
    "/assets/techopolis_logo.png",
  ];
  const Moddescriptions = [
    "Caveopolis is a mod that adds many colored stone variants for building, and spray paints for colouring different things to the colours you desire!",
    "This mod creates big multiblock structures to mine ores passively and automatically for you.",
    "Provides many cool, unique, and helpful items and blocks to assist your journey! See mod description for full list of new things added.",
    "Techopolis Mod that adds supporting blocks for the Techopolis Modpack.",
  ];
  return (
    <>
      <div className={styles.app}>
        <main>
          <section>
            <h2>About Us</h2>
            <p>
              Welcome to BBL, the home of all the Benbenlaw mods and modpacks
            </p>
          </section>
          <section>
            <h2>Modpacks</h2>
            <Carousel
              images={ModpackImages}
              descriptions={Modpackdescriptions}
            />
          </section>
          <section>
            <h2>Mods</h2>
            <ModCarousel images={ModImages} descriptions={Moddescriptions} />
          </section>
          <section></section>
        </main>
      </div>
    </>
  );
};
