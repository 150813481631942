import styles from "./Modpacks.module.css";
import React from "react";
import {Route, Link, Routes, useLocation} from 'react-router-dom';

export const Modpacks = () => {
  const ModpackImages = [
    "/assets/omniopolis.png",
    "/assets/frozenopolis.png",
    "/assets/techopolis2.png",
    "/assets/seaopolis.png",
    "/assets/techopolis.png",
    "/assets/cryptopolis.png",
    "/assets/caveopolis.png",
  ];
  const Modpackdescriptions = [
    "HELLO!",
    "The latest collab with Crypt1c and Benbenlaw! Can you survive the harsh ice world? Featuring custom mods and a heat system.",
    "Techopolis 2 is a technology based modpack. Featuring custom mod 'Opolis Miners' to produce ores for you. Play your way with 4 world types normal, hexlands, skyblock and super flat!",
    "An ocean-based survival and questing modpack. Featuring a completly customized world filled with various different mobs and structures.",
    "Techopolis is a technology based modpack based in a custom overworld with a focus on mining and exploration. Featuring custom machines, recipes and famous mods like Mekanism, Thermal and Immersive Engineering. Now with skyblock mode!",
    "The first cooperation pack between Benbenlaw and Crypt1C! Involving mods like Create, Immersive Engineering and even a couple of custom utils mods that help you through your journey",
    "Caveopolis is a questing based modpack for Minecraft 1.18.2. Journey your way down the world, creating new blocks and machines along the way. Features mods such as Thermal, Mekanism and Custom Machinery. How Low Can You Go?",
  ];
		
  const modpackName = useLocation().pathname.charAt(9).toUpperCase() + useLocation().pathname.substr(10)
  
  var imageToDisplay = "";
  var textToDisplay = "";
  var i = 0;
  ModpackImages.forEach((image) => {
	if (image.includes(modpackName.toLowerCase())) {
		imageToDisplay = image;
		textToDisplay = Modpackdescriptions[i];
	}
	i++
  })
  return (
    <>
      <div className={styles.app}>
        <main>
          <section>
            <h2>About {modpackName}</h2>
			<img src={imageToDisplay} />
          </section>
          <section>
		  {textToDisplay}
		  </section>
        </main>
      </div>
    </>
  );
};
