import { useState } from "react";
// import { CarouselProps } from "./types";
import React from "react";
import "./ModCarousel.css";
import {Route, Link, Routes, useNavigate} from 'react-router-dom';


function Carousel({ images, descriptions }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex(
      currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1
    );
  };

  const currentImage = images[currentImageIndex];
  const currentDescription = descriptions[currentImageIndex];

  const navigate = useNavigate();
  const navigateCarousel = () => {
	navigate("/mod/" + currentImage.substr(8).substr(0, currentImage.substr(8).length - 9));
  }
  
  return (
    <div className="carousel-containerM">
      <div className="image-container">
        <button className="prev-button" onClick={handlePrev}>
          Prev
        </button>
        <img src={currentImage} alt="carousel" className="carousel-image" onClick={navigateCarousel}/>
        <button className="next-button" onClick={handleNext}>
          Next
        </button>
      </div>
      <div className="description-container" onClick={navigateCarousel}>{currentDescription}</div>
    </div>
  );
}

export default Carousel;
